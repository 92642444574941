class SentryError extends Error {
  public data: unknown;

  constructor(err: string | Error, opts?: ErrorOptions & { data?: unknown }) {
    if (err instanceof Error) {
      super(err.message);
      this.cause = err.cause;
      this.name = err.name;
      this.stack = err.stack;
      this.message = err.message;
    } else {
      super(err, opts);
    }
    this.data = opts?.data || {};
  }
  toJSON() {
    return {
      SentryError: {
        name: this.name,
        message: this.message,
        cause: this.cause,
        stack: this.stack,
        data: this.data,
      },
    };
  }
}

export default SentryError;
