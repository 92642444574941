import { lazily } from "react-lazily";
import { importWithRetry } from "./importWithRetry";

type LazilyParam<T extends {}, U extends keyof T> = Parameters<
  typeof lazily<T, U>
>[0];
type LazilyResult<T extends {}, U extends keyof T> = ReturnType<
  typeof lazily<T, U>
>;

const lazilyWithRetry = <T extends {}, U extends keyof T>(
  componentImport: LazilyParam<T, U>
): LazilyResult<T, U> => {
  const importOrReloadToAvoidCachedJS: LazilyParam<T, U> = async () => {
    return await importWithRetry(componentImport);
  };

  return lazily(importOrReloadToAvoidCachedJS);
};

export default lazilyWithRetry;
