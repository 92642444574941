import * as Sentry from "@sentry/react";
import { ExtraErrorData } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  debug: process.env.NODE_ENV !== "production",
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: (integrations) => [
    ...integrations,
    new ExtraErrorData({
      depth: 10,
    }),
    new Integrations.BrowserTracing({
      tracePropagationTargets: [
        "localhost:3000",
        "https://conquer.housecallpro-staging.com",
        "https://conquer.housecallpro.com",
      ],
    }),
  ],
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0,
  release: process.env.REVISION,
  normalizeDepth: 11,
  environment: process.env.NODE_ENV,
  beforeBreadcrumb(breadcrumb, hint) {
    try {
      if (breadcrumb.category === "ui.click") {
        return { ...breadcrumb, targetHtml: hint?.event.target.innerHTML };
      }
    } catch (_err) {
      return breadcrumb;
    }
    return breadcrumb;
  },
  ignoreErrors: [
    // CON-557 - Error thrown in youtube-player code; not an actual bug
    "Failed to execute 'postMessage' on 'DOMWindow': The target origin provided ('https://www.youtube.com') does not match the recipient window's origin",
  ],
});
