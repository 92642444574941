import { forever } from "utils/forever";
import { DEBUG_FLAG_NAME, DEBUG_MODE_DISCLAIMER } from "./constants";

export const isDebugModeEnabled = () => {
  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get(DEBUG_FLAG_NAME) === "true";
};

export const showFalsePositiveMissingChunkDebugMessage = async (
  isDebugging: boolean,
  chunkSrc: string
) => {
  if (!isDebugging) return;

  const falsePositiveMessage = `${DEBUG_MODE_DISCLAIMER}  This means the browser was not able to initially load the chunk, but a manual request to its src was successful, so chunk will work correctly after refreshing the page.

   - Chunk source: ${chunkSrc}

Omitting the auto-refresh of the page as debug mode is active 
`;

  alert(falsePositiveMessage);

  await forever();
};

export const showDebugRefreshingMessage = () => {
  if (isDebugModeEnabled()) {
    alert(
      `$${DEBUG_MODE_DISCLAIMER}  First attempt to load the chunk failed. Refreshing the page`
    );
  }
};
