import {
  isDebugModeEnabled,
  showFalsePositiveMissingChunkDebugMessage,
} from "./debugMode";
import { type ChunkLoadError } from "./types";
import { v4 as uuidv4 } from "uuid";
import axios, { type AxiosError } from "axios";
import { refreshWindow } from "./tools";
import SentryError from "utils/SentryError";
import { captureException } from "@sentry/react";
import { CHUNK_REFRESHED_PREFIX } from "./constants";

const storage = sessionStorage;

export const hasChunkFailedBefore = ({
  request: chunkUrl,
}: ChunkLoadError): boolean => {
  const valueForTruthiness = "true";
  const chunkFailedKey = `${CHUNK_REFRESHED_PREFIX}--${chunkUrl}`;

  const hasFailed = storage.getItem(chunkFailedKey) === valueForTruthiness;

  if (hasFailed) {
    storage.removeItem(chunkFailedKey);
    return true;
  }

  storage.setItem(chunkFailedKey, valueForTruthiness);
  return false;
};

export const isAChunkLoadError = (error: any): error is ChunkLoadError => {
  if (
    error?.type === "error" &&
    error?.name === "ChunkLoadError" &&
    typeof error?.request === "string"
  ) {
    return true;
  }
  return false;
};

const getSearchParamToAvoidCache = () => {
  return `rand=${uuidv4()}`;
};

export const getDebugChunkSrc = (chunkSrc: string) => {
  const extRegExp = /(\.[^./]+)$/;

  return chunkSrc.replace(extRegExp, "/debug$1");
};

export const getChunkSrc = (chunkSrc: string) => {
  return `${chunkSrc}?${getSearchParamToAvoidCache()}`;
};

const createSentryErrorForMissingChunk = ({
  fetchError,
  chunkSrc,
  originalChunkSrc,
}: {
  fetchError: AxiosError;
  originalChunkSrc: string;
  chunkSrc: string;
}): SentryError => {
  const isDebugging = isDebugModeEnabled();

  const errorName = `${
    isDebugging ? "[DEBUGGING__PLEASE-IGNORE]--" : ""
  }ChunkLoadError`;

  const errorMessage = `${
    isDebugging ? "[DEBUGGING__PLEASE-IGNORE] -- " : ""
  }Failed loading a chunk`;
  const error = new SentryError(errorMessage, {
    cause: fetchError.cause,
    data: {
      chunkSrc: originalChunkSrc,
      chunkRequest: chunkSrc,
      isDebugging,
      name: fetchError.name,
      errorCode: fetchError.code,
      statusCode: fetchError.request?.status,
      statusText: fetchError.request?.statusText,
      response: fetchError.request?.response,
    },
  });

  error.name = errorName;
  error.stack = fetchError.stack;

  return error;
};

/** We may want to ensure the chunk exist before logging the error in Sentry.
 * So this function performs an axios request to the chunk url, where:
 *
 *  - An ok status means it is correct and it failed due to an unrelated unknown cause like network issues or server unreachable.
 * So, reloading the page should work.
 *  -
 *
 */
export const manuallyFetchFailingChunk = async (
  chunkLoadError: ChunkLoadError
) => {
  const isDebugging = isDebugModeEnabled();
  const chunkSrc = isDebugging
    ? getDebugChunkSrc(chunkLoadError.request)
    : getChunkSrc(chunkLoadError.request);

  try {
    await axios.get(chunkSrc);

    await showFalsePositiveMissingChunkDebugMessage(
      isDebugging,
      chunkLoadError.request
    );

    await refreshWindow();
  } catch (fetchError) {
    let error: Error =
      fetchError instanceof Error
        ? fetchError
        : new Error("ChunkLoadError", {
            cause: JSON.stringify(fetchError),
          });

    if (axios.isAxiosError(fetchError)) {
      error = createSentryErrorForMissingChunk({
        originalChunkSrc: chunkLoadError.request,
        fetchError,
        chunkSrc,
      });
    } else {
      error = new SentryError(error);
    }
    captureException(error, {
      level: "fatal",
    });
    throw error;
  }
};
