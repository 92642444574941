import SentryError from "utils/SentryError";
import {
  hasChunkFailedBefore,
  isAChunkLoadError,
  manuallyFetchFailingChunk,
} from "./chunks";
import { showDebugRefreshingMessage } from "./debugMode";
import { captureException } from "@sentry/react";
import { refreshWindow } from "./tools";

const throwAnInvalidError = () => {
  const invalidError = new Error("InvalidError");
  invalidError.name = "InvalidError";
  invalidError.message =
    "This line of code should not be hit. This means something unexpected was modified in the lines above the place where this error was thrown.";
  throw invalidError;
};

export const importWithRetry = async <IModule>(
  componentImport: () => Promise<IModule>
) => {
  try {
    const component = await componentImport();

    return component;
  } catch (error) {
    if (isAChunkLoadError(error)) {
      if (hasChunkFailedBefore(error)) {
        /*
          Page has been already refreshed
          Last attempt to check if the chunk isn't really there
        */
        await manuallyFetchFailingChunk(error);

        //manuallyFetchFailingChunk should never resolve, so throwing an error in case it does
        throwAnInvalidError();
      }

      showDebugRefreshingMessage();

      // Assuming that the user is not on the latest version of the application.
      // Let's refresh the page immediately.
      await refreshWindow();

      //refreshWindow should never resolve, so throwing an error in case it does
      throwAnInvalidError();
    }

    // Chunk error not related with a chunk load error
    captureException(new SentryError(error as string | Error), {
      level: "fatal",
    });
    throw error;
  }
};
