import Typography from "housecall-ui/dist/DesignSystem/DataDisplay/Typography/Typography";
import Box from "housecall-ui/dist/DesignSystem/Layout/Box/Box";
import WarningOutlined from "housecall-ui/dist/IconsAndImages/Icons/WarningOutlined";
import { Link, makeStyles } from "housecall-ui";

const useStyles = makeStyles((theme) => ({
  icon: {
    height: "60px",
    width: "60px",
    color: theme.palette.text.primary,
  },
  blackPrimary: {
    color: theme.palette.text.primary,
  },
}));

const REACT_APP_HOUSECALLWEB_BASE_URL =
  process.env.REACT_APP_HOUSECALLWEB_BASE_URL;
const DASHBOARD_URL = REACT_APP_HOUSECALLWEB_BASE_URL + "/pro/dashboard";

const SomethingWentWrong = () => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <WarningOutlined color="alert" size="large" backgroundCircle />
      <Typography variant="h2" className={classes.blackPrimary} gutterBottom>
        Something went wrong
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Please reload your browser window.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        If the problem persists, please contact support using the blue chat
        bubble in the corner of the{" "}
        <Link href={DASHBOARD_URL}>Housecall Pro dashboard page.</Link>
      </Typography>
    </Box>
  );
};

export default SomethingWentWrong;
