export class ControlledPromise<T> extends Promise<T> {
  public resolvePromise: (value: T) => void = () => {};
  public rejectPromise: (value: T) => void = () => {};

  constructor(
    cb: (
      resolve: (value: T) => void,
      reject: (value: T) => void
    ) => void = () => {}
  ) {
    let resolvePromise: (value: T) => void = () => {};
    let rejectPromise: (value: T) => void = () => {};
    super((resolve, reject) => {
      resolvePromise = resolve;
      rejectPromise = reject;
      return cb(resolve, reject);
    });
    this.resolvePromise = resolvePromise;
    this.rejectPromise = rejectPromise;
  }

  then: Promise<T>["then"] = (onFulfilled, onRejected) => {
    return super.then(onFulfilled, onRejected);
  };
}
