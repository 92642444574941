import CircularProgress from "housecall-ui/dist/DesignSystem/Feedback/Progress/CircularProgress";
import Grid from "housecall-ui/dist/DesignSystem/Layout/Grid/Grid";
import makeStyles from "housecall-ui/dist/DesignSystem/Styles/makeStyles";

const useStyles = makeStyles({
  spinner: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.8,
    backgroundColor: "white",
    zIndex: 99,
  },
});

const Spinner = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      color="accent"
      className={classes.spinner}
    >
      <CircularProgress color="secondary" />
    </Grid>
  );
};

export default Spinner;
