import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import SomethingWentWrong from "./SomethingWentWrong";

interface ErrorBoundaryType {
  (props: { children: React.ReactNode; fallback?: JSX.Element }): JSX.Element;
}

const ErrorBoundary: ErrorBoundaryType = ({
  children,
  fallback = <SomethingWentWrong />,
}) => (
  <SentryErrorBoundary
    fallback={fallback}
    beforeCapture={(scope) => {
      scope.setTag("codeowners", "conquer");
      scope.setTag("error_boundary", "true");
    }}
  >
    {children}
  </SentryErrorBoundary>
);

export default ErrorBoundary;
