import { type ComponentType, type LazyExoticComponent, lazy } from "react";
import { importWithRetry } from "./importWithRetry";

type LazyParam<T extends ComponentType<any>> = Parameters<typeof lazy<T>>[0];

const lazyWithRetry = <T extends ComponentType<any>>(
  componentImport: LazyParam<T>
): LazyExoticComponent<T> => {
  return lazy(() => importWithRetry(componentImport));
};

export default lazyWithRetry;
