import { Suspense } from "react";

import Spinner from "components/Progress/Spinner";

import ErrorBoundary from "services/ErrorBoundary";
import lazyWithRetry from "utils/lazyWithRetry";

const IndexRoute = lazyWithRetry(() => import("routes"));

const App = () => (
  <Suspense fallback={<Spinner />}>
    <ErrorBoundary>
      <IndexRoute />
    </ErrorBoundary>
  </Suspense>
);

export default App;
